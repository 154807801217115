<script>
    import { url, isActive, prefetch } from '@roxi/routify';
    import { fly } from 'svelte/transition';
</script>

<a
    in:fly={{ x: -200, delay: 500, duration: 800 }}
    out:fly={{ x: -20 }}
    id="home"
    href={$url('/')}
    on:mouseover={() => ($isActive('/') ? null : prefetch('/index'))}>
    <svg
        fill={$isActive('./index') ? 'none' : 'var(--brand)'}
        stroke={$isActive('./index') ? 'var(--brand)' : 'var(--text)'}
        xmlns="http://www.w3.org/2000/svg"
        height="40px"
        width="40px"
        data-name="a"
        viewBox="0 0 24 24"
        x="0px"
        y="0px"><title>rounded</title>
        <path
            d="M19.495 10.432l-6-6.75a2 2 0 00-2.99 0l-6 6.75A2 2 0 004 11.76V17a3 3 0 003 3h2a1 1 0 001-1v-1.893a2.074 2.074 0 011.664-2.08A2.001 2.001 0 0114 17v2a1 1 0 001 1h2a3 3 0 003-3v-5.24a2 2 0 00-.505-1.328z" /></svg>
</a>

<style lang="sass">svg {
  padding: 0.4rem;
  cursor: pointer;
  transition: all 300ms; }
  svg:hover {
    fill: var(--brand); }
</style>
