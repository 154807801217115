<script>
    import { LottiePlayer } from '@lottiefiles/svelte-lottie-player';
    import { fade } from 'svelte/transition';
</script>

<div class="outer" in:fade={{ delay: 500 }}>
    <h1 class="coming-soon" style="top:8rem;">Coming Soon...</h1>
    <div class="inner">
        <LottiePlayer
            src="https://gist.githubusercontent.com/FractalHQ/ef406806133b731df9d9d8f925d9b986/raw/2ba47639c62e91c2b615eb547a03794cf8a15c7d/puzzle.json"
            autoplay={true}
            loop={true}
            renderer="svg"
            background="transparent"
            height={200}
            width={200}
            controls={''}
            controlsLayout={''} />
    </div>
</div>

<style lang="sass">h1 {
  font-family: var(--font-secondary);
  text-align: center;
  font-weight: 500; }

.inner {
  border: 1px solid whitesmoke;
  border-radius: 10px;
  width: min-content;
  overflow: hidden;
  margin-top: 3rem;
  max-width: 50vw;
  margin: auto;
  z-index: 10; }

.coming-soon {
  margin: 4rem auto 2rem auto;
  text-align: center;
  font-size: 3rem;
  width: 100%; }
</style>
