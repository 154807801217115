<script>
    import ThemeToggle from '../shared/ThemeToggle.svelte';
    import Nav from './Nav.svelte';
    import Home from './HomeIcon.svelte';
</script>

<header>
    <Home />
    <ThemeToggle />
    <Nav />
</header>

<style lang="sass">header {
  width: 100vw;
  height: 3.5rem;
  display: flex;
  z-index: 1; }
</style>
