<script>
    import { fly } from 'svelte/transition';
    import format from 'date-fns/format';

    let date = format(new Date(), 'iiii, MMMM yyy');
</script>

<p class="date">{date}</p>

<style>
    .date {
        color: var(--text);
        opacity: 50%;
        bottom: 13rem;
        font-size: 1rem;
        margin: 0.5rem 0 0 1rem;
        font-weight: 500;
        max-width: max-content;
    }

    @media only screen and (max-width: 600px) {
        .date {
            margin: 0.2rem 0 0 0.7rem;
            visibility: collapse;
        }
    }
</style>
