<script>
    import { onMount, afterUpdate } from 'svelte';
    import { fly, fade } from 'svelte/transition';
    import Dexie from 'dexie';
    import 'chart.js';

    let data = [];
    let type = 'bar';

    const db = new Dexie('TaskDatabase');
    db.version(1).stores({
        tasks: '++id, title, duration, date, tags',
    });

    async function getTasks() {
        data = await db.tasks.toArray();
        data = data;
    }
    getTasks();

    $: labels = [];
    $: durations = [];
    let labels;
    let ctx;
    let myChart;

    function createChart() {
        ctx = document.getElementById('myChart');
        if (myChart) myChart.destroy();
        myChart = new Chart(ctx, {
            type: type,
            data: {
                labels,
                datasets: [
                    {
                        label: 'Seconds',
                        data: durations,
                        backgroundColor: [
                            '#CAF0E7',
                            '#80DCC6',
                            '#32CAA5',
                            'rgba(75, 192, 192, 0.2)',
                            '#25A586',
                            '#22967A',
                        ],
                        borderColor: [
                            'var(--text)',
                            'var(--text)',
                            'var(--text)',
                            'var(--text)',
                            'var(--text)',
                            'var(--text)',
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
            },
        });
    }

    onMount(async () => {
        await getTasks();
        labels = data.map(({ title }) => title);
        durations = data.map(({ duration }) => Math.floor(duration / 1000));
        createChart();
    });

    afterUpdate(createChart);
</script>

<div class="buttons" in:fade={{ delay: 500 }} out:fade>
    <button
        class:active={type == 'bar' ? 'active' : null}
        on:click={() => (type = 'bar') && createChart}>Bar</button>
    <button
        class:active={type == 'pie' ? 'active' : null}
        on:click={() => (type = 'pie')}>Pie</button>
    <button
        class:active={type == 'polarArea' ? 'active' : null}
        on:click={() => (type = 'polarArea')}>Polar</button>
    <button
        class:active={type == 'radar' ? 'active' : null}
        on:click={() => (type = 'radar')}>Radar</button>
</div>
<div class="container" in:fly={{ x: 200, delay: 300 }} out:fly={{ x: 200 }}>
    <div class="chart">
        <canvas class="bar-chart" id="myChart" width="400" height="400" />
    </div>
</div>

<style lang="sass">@media only screen and (max-width: 600px) {
  .buttons {
    position: absolute;
    bottom: 0;
    width: 100vw;
    max-width: 100vw; }
  button {
    position: relative; }
  .chart {
    margin: 10vh 0 0 0; } }

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin: 1rem auto; }

button {
  background: transparent;
  border: 1px solid var(--brand);
  margin: 0 1rem;
  outline: none;
  padding: .2rem;
  width: 60px;
  color: var(--text-secondary); }

.active {
  color: var(--text);
  border: 1px solid var(--brand-secondary); }

.container {
  height: 100vh;
  transition: 0;
  display: absolute;
  width: 100vw; }

.chart {
  cursor: pointer;
  overflow: hidden;
  padding: 0 auto;
  width: 100%;
  max-width: 500px;
  margin: 1rem auto 0 auto; }

canvas {
  color: white;
  font-family: var(--font-primary); }

.bar-chart {
  margin: auto;
  transition: 0;
  max-width: 90vw;
  max-height: 90vh; }
</style>
