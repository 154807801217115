<script>
    export let username, password, rememberMe;
</script>

<form>
    <input
        required
        minlength="3"
        maxlength="25"
        id="username"
        type="text"
        bind:value={username}
        placeholder="Username" />

    <input
        required
        type="password"
        bind:value={password}
        placeholder="Password" />

    <label class="remember-me">
        <input type="checkbox" bind:checked={rememberMe} /> Remember Me
    </label>

    <slot />
</form>

<style lang="sass">input:not([type=checkbox]) {
  border: 1px solid var(--bg-secondary);
  font-family: var(--font-primary);
  outline-color: var(--brand);
  padding: 0.4rem 0.8rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  color: var(--dark);
  font-size: inherit;
  text-align: center;
  transition: 500ms;
  margin: 1rem auto;
  padding: 0.2rem;
  display: block;
  outline: none; }

.remember-me {
  margin: 0.5rem auto;
  text-align: center;
  font-weight: 500;
  font-size: small;
  opacity: 75%; }

label > input {
  margin: 0.5rem 0.5rem 1rem auto; }

form {
  padding-top: 1.3rem; }
</style>
