<script>
    import { fade, fly } from 'svelte/transition';
</script>

<div class="wrapper" transition:fade>
    <img
        in:fly={{ y: 1000, duration: 3000, delay: 300 }}
        class="wave1"
        src="svg/skywaves/skywave1.svg"
        alt="skywave1" />
    <img
        in:fly={{ y: 1000, duration: 3000, delay: 500 }}
        class="wave2"
        src="svg/skywaves/skywave2.svg"
        alt="skywave1" />
    <img
        in:fly={{ y: 1000, duration: 4000, delay: 700 }}
        class="wave3"
        src="svg/skywaves/skywave3.svg"
        alt="skywave1" />
    <img
        in:fly={{ y: 1000, duration: 5000, delay: 1000 }}
        class="wave4"
        src="svg/skywaves/skywave4.svg"
        alt="skywave1" />
</div>

<style lang="sass">.wave1, .wave2, .wave3, .wave4 {
  filter: var(--filter);
  position: absolute;
  min-width: 120vw;
  max-width: 100vw;
  bottom: -1rem;
  left: -10%;
  margin: 0; }

.wave1 {
  bottom: -2rem;
  z-index: -1; }

.wave2 {
  z-index: -2; }

.wave3 {
  z-index: -3; }

.wave4 {
  z-index: -4; }
</style>
