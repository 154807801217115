<script>
  import { Router } from "@roxi/routify";
  import { routes } from "../.routify/routes";
  // import "../assets/reset.css";
  // import "../assets/global.css";
</script>

<Router {routes} />

<style global>
  @font-face {
	font-family: 'Fredericka the Great';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Fredericka the Great'), url('../fonts/Fredericka-the-Great.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
		U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Quicksand';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Quicksand'), url('../fonts/Quicksand-VariableFont.ttf') format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
		U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:global(:root),
:global(:root.light),
:global(:root.dark) {
	--font-primary: Quicksand, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	--font-secondary: 'Fredericka the Great', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

:global(:root) {
	--justify-important: center;
	--box-shadow: 2px 2px 10px;
	--width-card-medium: 460px;
	--width-card-wide: 800px;
	--width-content: 1080px;
	--hover-brightness: 1.2;
	--justify-normal: left;
	--border-radius: 5px;
	--width-card: 285px;
	--line-height: 1.5;
}

:global(:root),
:global(:root.light) {
	--brand: #00f497;
	--brand-secondary: #5222d0;
	--brand-accent: #920de9;
	--brand-swap-a: #00f497;
	--brand-swap-b: #5222d0;
	--brand-swap-glow: 0 0 30px 10px #00f49650;
	--bg-secondary: rgb(245, 245, 245);
	--bg: #fff;
	--dark-gray: #323232;
	--shadow: #f4f4f4;
	--text: #212324;
	--text-secondary: #999;
	--text-invert: #f4f4f4;
	--dark: #212324;
	--dark-accent: #414b5d;
	--gray: #d7dbde;
	--filter: none;
}

:global(:root.dark) {
	--box-shadow: 2px 2px 10px;
	--brand: #00f497;
	--brand-secondary: #5222d0;
	--brand-accent: #920de9;
	--brand-swap-a: #5222d0;
	--brand-swap-b: #00f497;
	--brand-swap-glow: 0 0 30px 10px #5322d045;
	--bg: #212324;
	--bg-secondary: #282f3b;
	--secondary: #e20de9;
	--dark-gray: #5e6a72;
	--shadow: #00f497;
	--text: #f7f7f7;
	--text-secondary: #999;
	--text-invert: #212324;
	--dark: #212324;
	--dark-accent: #414b5d;
	--gray: #d7dbde;
	--filter: saturate(0.1) brightness(0.2);
}

:global(html) {
	min-height: 100vh
}

/* ? Clear placeholder text when focused */
:global(input:focus::-webkit-input-placeholder) {
	color: transparent;
}

/* ? Fix red outline on FireFox */
:global(input:invalid) {
	-moz-mox-shadow: none;
	box-shadow: none;
}

:global(*) {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	user-select: none;
}

:global(body) {
	font-family: var(--font-primary);
	line-height: var(--line-height);
	transition: background 300ms;
	background: var(--bg);
	color: var(--text);
	padding: 0;
	margin: 0;
}

:global(button) {
	border: 2px solid transparent;
	border-radius: 5px;
	font-size: medium;
	font-weight: bold;
	line-height: var(--line-height);
	font-family: var(--font-primary);
	padding: 0.5rem 2rem;
	transition: 300ms;
}

:global(button:focus) {
	outline: none
}

:global(button:hover) {
	cursor: pointer;
	filter: brightness(var(--hover-brightness));
	transition: 300ms;
}

:global(input) {
	outline: none;
}

/* ? Input Placeholder text color */
/* * Chrome, Firefox, Opera, Safari 10.1+ */
:global(::placeholder),

:global(:-ms-input-placeholder),

:global(::-ms-input-placeholder) {
	color: var(--text-secondary);
	/* * Firefox */
	opacity: 1;
}

:global(button[disabled]:hover) {
	filter: none;
}

:global([hidden]) {
	display: none;
}
</style>