<script>
    import { onDestroy, onMount } from 'svelte';
    import { fly } from 'svelte/transition';
    // timer
    import Stopwatch from './_components/time/Stopwatch.svelte';
    import Controls from './_components/time/Controls.svelte';
    import Save from './_components/time/Save.svelte';
    import Tasks from './_components/Tasks.svelte';
    // UI
    import Tooltip from '../_components/shared/Tooltip.svelte';
    import Tags from '../_components/shared/Tags.svelte';
    import Menu from './_components/Menu.svelte';
    // Utils
    import { time } from './_components/time/utils/time.js';
    import setDate from 'date-fns/setDate';
    import Dexie from 'dexie';
    import download from 'downloadjs';
    import Time from '../_components/shared/Time.svelte';

    const db = new Dexie('TaskDatabase');
    db.version(1).stores({
        tasks: '++id, title, duration, date, tags',
    });

    let showSave = false;

    // Timer Variables
    let unsubscribe;
    let duration = 0;
    let previous = 0; // duration before the pause button is pressed
    $: recording = !!unsubscribe;
    $: lapsed = !!duration;

    //  Tasks Variables
    $: tasks = [];
    $: title = '';
    $: disabled = title.length > 0 ? false : true; // Disable save button & tooltip

    // Tag Variables
    let tag = '';
    let fav = [];
    let defaultFavorites = ['Nature', 'Work', 'Leisure', 'Deep Work', 'Social'];
    $: favorites = ['Nature', 'Work', 'Leisure', 'Deep Work', 'Social'];
    $: tags = [];

    // Timer Functions
    function start() {
        // combines the duration to the time retrieved from the readable store
        unsubscribe = time.subscribe((value) => {
            duration = value + previous; // add the previous value to the current duration
        });
        showSave = true;
    }

    function terminate() {
        // check if unsubscribe is truthy (in case the stop button is pressed after the pause button)
        if (unsubscribe) {
            // unsubscribe from the readable store
            unsubscribe();
            unsubscribe = null;
        }
    }

    // unsubscribe from the readable store and reset the values
    function stop() {
        duration = 0;
        previous = 0;
        showSave = true; // reactivate save button
        // tag = '';
        // tag = tag;
        tags = [];
        // tags = tags;
        tag = tags;
        favorites = defaultFavorites;
        // favorites = favorites;
        title = '';
        terminate();
    }

    // unsubscribe from the store and set previous == duration
    function pause() {
        recording = !recording;
        previous = duration;
        terminate();
        showSave = true;
    }

    // Tag Functions
    function handleTags(event) {
        tag = event.detail.tags;
        tags = tags;
    }

    function removeTag(fav) {
        tags = tags.filter((tags) => tags !== tag);
        tag = tags;
    }

    function addFav(fav) {
        tags = [...tag, fav];
        tag = tags;
        favorites = favorites.filter((favorites) => favorites !== fav);
    }

    onMount(async () => {
        tags = tags; // Refreshes tags on page reload to avoid ghost tags
        start()
    });

    // Task Functions
    async function getTasks() {
        tasks = await db.tasks.toArray();
    }
    getTasks();

    async function saveTask() {
        recording = !recording;
        await db.tasks.add({
            title: title,
            duration: duration,
            date: setDate(new Date(), 4),
            tags: tags,
        });
        tasks = await db.tasks.toArray();
        stop();
    }

    async function deleteAll() {
        await db.tasks.where('date').below(setDate(new Date(), 4)).delete();
        tasks = await db.tasks.toArray();
        console.log(
            'Tasks deleted! Current tasks are: ' + JSON.stringify(tasks, 0, 2)
        );
    }

    async function deleteTask(event) {
        const id = event.detail.id;
        await db.tasks
            .where('id')
            .equals(id)
            .delete()
            .catch(function (error) {
                console.log('Error: ' + error);
            });
        tasks = await db.tasks.toArray();
    }

    onDestroy(() => {
        terminate(); // 🚨 Unsubscribe from the store to avoid memory leaks
    });
</script>

<div
    class="time"
    in:fly={{ x: -200, delay: 500, duration: 1000 }}
    out:fly={{ x: -20 }}>
    <Time />
</div>

<Menu on:deleteAll={deleteAll} />

<div class="page" in:fly={{ x: -200, delay: 300 }} out:fly={{ x: -200 }}>
    <div class="stopwatch-wrapper">
        <Stopwatch {duration} />
    </div>
    <h1 class="task-title">{title}</h1>
    <h2 class="task-tags">{tags.join(' · ')}</h2>

    <Tasks {tasks} on:deleteTask={deleteTask} />

    <div class="controls-container">
        <div class="stop-start-controls">
            <Controls
                on:start={start}
                on:stop={stop}
                on:pause={pause}
                {recording}
                {lapsed} />
        </div>

        <!-- 💬 Task Input -->
        <div class="input-save">
            <input
                in:fly={{ y: 200, delay: 800 }}
                placeholder="What are you doing?"
                bind:value={title}
                class="task-input" />
            <div class="save">
                {#if lapsed && showSave}
                    <Tooltip
                        color={`var(--bg)`}
                        left
                        tip={disabled ? '↙ Name your task to save!' : ''}>
                        <Save {disabled} save={saveTask} />
                    </Tooltip>
                {/if}
            </div>
        </div>
    </div>
    
    <div
    class="tags-container"
    in:fly={{ y: 200, delay: 1000 }}
    out:fly={{ x: -200 }}>
    <div class="tag-tooltip" style="z-index:10">
        <Tooltip
        color={`var(--bg)`}
        right
        tip="Add a Tag with the [Tab] or
        [Enter] keys.">
        <span class="tooltip-label">ℹ</span>
    </Tooltip>
</div>
<div class="tags">
    <Tags
    {tags}
    on:tags={handleTags}
    addKeys={[9, 13]}
    onlyUnique={true}
    removeKeys={[8, 27]}
    placeholder={'Add Tags'}
    autoComplete={favorites}
    name={'task-tags'}
    allowBlur={true} />
</div>
<div class="favs">
    {#each favorites as fav}
    <button on:click={() => addFav(fav)} class="fav"> {fav} </button>
    {/each}
</div>
</div>
</div>

<style lang="sass">.time {
  font-family: Courier New, Courier, monospace;
  position: absolute;
  color: var(--text);
  width: max-content;
  font-size: 1.5rem;
  font-weight: 500;
  left: 1rem;
  top: 3rem; }

.fav {
  font-family: var(--font-primary);
  background: var(--bg-secondary);
  text-justify: inter-character;
  color: var(--text-secondary);
  overflow-wrap: breakword;
  word-break: break-word;
  word-wrap: break-word;
  border-radius: .5rem;
  margin: auto .15rem;
  white-space: nowrap;
  position: relative;
  display: grid-col;
  padding: 0 .4rem;
  font-weight: 300;
  font-size: 1rem;
  height: content;
  cursor: pointer;
  overflow: wrap;
  width: content;
  outline: none; }

.favs {
  grid-template-rows: 1fr;
  grid-auto-columns: 1fr;
  word-wrap: break-word;
  border-radius: .5rem;
  width: min-content;
  padding: .1rem;
  display: flex;
  height: 100%; }

.tags {
  border-radius: 1rem;
  max-height: initial;
  color: var(--text);
  flex-grow: 1; }

.tags-container {
  margin: auto 1rem 0 1rem;
  display: inline-flex;
  border-radius: 1rem;
  max-height: initial;
  position: absolute;
  max-width: 1px;
  bottom: 10rem;
  height: 2rem;
  z-index: 0;
  width: 1px; }

.tooltip-label {
  color: var(--text-secondary);
  vertical-align: middle;
  margin-right: .3rem;
  line-height: 200%;
  opacity: 50%; }

.stop-start-controls {
  margin-right: 3rem; }

.stopwatch-wrapper {
  margin: 1rem auto;
  width: 100%;
  display: flex; }

.controls-container {
  position: fixed;
  bottom: 4rem;
  width: 100%; }

.task-title {
  font-family: var(--font-primary);
  overflow-wrap: break-word;
  max-height: fit-content;
  word-wrap: break-word;
  text-align: center;
  min-height: 3rem;
  font-size: 3rem;
  display: block;
  overflow: wrap;
  hyphens: auto;
  z-index: 1; }

.task-tags {
  font-family: var(--font-primary);
  overflow-wrap: break-word;
  max-height: fit-content;
  word-wrap: break-word;
  text-align: center;
  min-height: 3rem;
  font-size: 1rem;
  display: block;
  overflow: wrap;
  hyphens: auto;
  z-index: 1; }

.page {
  transition: background 500ms;
  background: var(--bg);
  color: var(--text);
  position: absolute;
  max-width: 100vw;
  overflow-x: clip;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  right: 0;
  down: 0;
  left: 0;
  top: 0; }

input {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid var(--text-secondary);
  font-family: var(--font-primary);
  margin: auto auto .8rem 1rem;
  border-radius: 100px;
  align-items: center;
  color: var(--dark);
  text-align: center;
  border-width: 1px;
  font-size: medium;
  max-width: 72.5%;
  padding: .5rem;
  outline: none;
  bottom: 4rem;
  flex-grow: 1;
  left: 1rem; }

.save {
  position: absolute;
  right: 2.3rem;
  bottom: 10rem;
  z-index: 1; }

.input-save {
  align-items: center;
  position: absolute;
  overflow-x: clip;
  height: 5rem;
  max-width: 100vw;
  width: 100%; }

.task-input {
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 10rem; }

@media only screen and (max-width: 600px) {
  .time {
    font-size: 1rem;
    top: 3.5rem; }
  .favs {
    visibility: collapse;
    margin: 0 0 -10rem 0; }
  .tags-container {
    bottom: 8rem;
    left: 1rem;
    z-index: 0;
    margin: 0; }
  .save {
    right: .35rem;
    bottom: 7.8rem; }
  .task-input {
    transform: translateY(-3rem); }
  .task-title {
    font-family: var(--font-primary);
    text-align: center;
    font-size: 1.5rem;
    margin: 0 auto;
    z-index: 10;
    width: 100%; }
  input {
    width: 70%; }
  .stopwatch-wrapper {
    margin: 2rem auto 0 auto; }
  .task-tags {
    transform: translateY(-1rem);
    font-weight: 300;
    font-size: .8rem;
    margin: 0 0 -2rem 0; } }

@media only screen and (min-width: 600px) {
  .tags-container {
    margin: auto 3rem 0 5vw;
    border-radius: 1rem;
    position: absolute;
    display: flex;
    bottom: 1rem;
    height: 2rem; }
  .stopwatch-wrapper {
    margin: 7rem auto 4rem auto; }
  .task-title {
    margin: -4rem auto;
    font-size: 2.5rem; }
  .task-tags {
    margin: 4.5rem 0 -2rem 0; }
  input {
    margin: 0 auto 9rem 5vw;
    padding: 1rem;
    bottom: 0; }
  .controls-container {
    position: fixed; }
  .input-save {
    display: inline-flex;
    align-items: center; }
  .save {
    bottom: 9rem;
    right: 2.3rem; } }

input:placeholder {
  font-family: var(--font-primary);
  color: var(--text); }

/* Hack to clear placeholder text on focus */
input:focus::-webkit-input-placeholder,
input:focus::-moz-input-placeholder {
  color: transparent; }

input::-webkit-input-placeholder,
input::-moz-input-placeholder {
  color: transparent; }
</style>
