<script>
    import { fly } from 'svelte/transition';
    import { onMount } from 'svelte';

    let time = new Date();

    function zeroPadded(number) {
        return number >= 10 ? number.toString() : `0${number}`;
    }

    $: hours = time.getHours() % 12;
    $: minutes = zeroPadded(time.getMinutes());
    $: seconds = zeroPadded(time.getSeconds());

    let ampm = time.getHours() < 12 ? 'AM' : 'PM';

    onMount(() => {
        const interval = setInterval(() => {
            time = new Date();
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });
</script>

<h1 class="time">{hours}:{minutes}:{seconds} {ampm}</h1>

<style>
    .time {
        background: linear-gradient(
            270deg,
            var(--brand),
            var(--brand-secondary)
        );
        background-size: 400% 400%;
        -webkit-animation: timeflow 15s ease infinite;
        -moz-animation: timeflow 15s ease infinite;
        animation: timeflow 15s ease infinite;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @-webkit-keyframes timeflow {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @-moz-keyframes timeflow {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @keyframes timeflow {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    .time {
        font-weight: 500;
    }
</style>
