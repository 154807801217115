<script>
    import { fly } from 'svelte/transition';
    export let save;
    export let width = 55;
    export let disabled;
</script>

<div class="save" transition:fly={{ x: 100 }}>
    <!-- 💾 Save -->
    <button on:click={save} {disabled}>
        <svg
            {width}
            height={width}
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 1C0 0.447716 0.447715 0 1 0H16.5973C16.8557 0 17.104 0.0999947 17.2903 0.279031L22.693 5.47185C22.8891 5.6604 23 5.92074 23 6.19282V21C23 21.5523 22.5523 22 22 22H1C0.447715 22 0 21.5523 0 21V1Z"
                fill="var(--dark-gray)" />
            <path
                d="M7.375 14.25H15.625V21.125H7.375V14.25ZM10.125 1.875H12.875V4.625H10.125V1.875Z"
                fill="var(--brand-swap-b)" />
            <path
                d="M5 13.8571C5 12.2814 5.34626 11.9524 7.00064 11.9524H16C17.6544 11.9524 18 12.2814 18 13.8571V21H22L21.9985 5.87714L16.8779 1H15V6.71429H5V1H1V21H5V13.8571Z"
                fill="var(--brand-swap-a)" />
        </svg>
    </button>
</div>

<style lang="sass">button {
  padding: 1rem;
  border-radius: 5rem; }

svg {
  background: transparent; }

button:focus {
  outline: 0; }

@media only screen and (max-width: 600px) {
  button {
    padding: .2rem .4rem;
    border-radius: 5rem; }
  svg {
    width: 75%; } }
</style>
