<script>
    import { fly } from 'svelte/transition';

    let theme;

    // function initTheme() {
    //     // * On first visit - Set theme using OS preference.
    //     try {
    //         if (!('theme' in localStorage)) {
    //             if (window.matchMedia('(prefers-color-scheme: light)').media) {
    //                 document.documentElement.className = 'light';
    //                 localStorage.setItem('theme', 'light');
    //                 theme = 'light';
    //             } else if (
    //                 window.matchMedia('(prefers-color-scheme: dark)').media
    //             ) {
    //                 document.documentElement.className = 'dark';
    //                 localStorage.setItem('theme', 'dark');
    //                 theme = 'dark';
    //             }
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }
    // onMount(async () => {
    //     try {
    //         initTheme();
    //     } catch (error) {
    //         console.error(error);
    //     }
    // });
    function initTheme() {
        if (!('theme' in localStorage)) {
            document.documentElement.className = 'light';
            localStorage.setItem('theme', 'light');
            theme = 'light';
        }
    }
    initTheme()

    function updateTheme() {
        if (localStorage.getItem('theme') == 'light') {
            document.documentElement.className = 'light';
            theme = 'light';
        } else if (localStorage.getItem('theme') == 'dark') {
            document.documentElement.className = 'dark';
            theme = 'dark';
        }
    }
    updateTheme()

    function toggleTheme() {
        if (theme == 'light') {
            document.documentElement.className = 'dark';
            localStorage.setItem('theme', 'dark');
            theme = 'dark';
        } else if (theme == 'dark') {
            document.documentElement.className = 'light';
            localStorage.setItem('theme', 'light');
            theme = 'light';
        }
    }
</script>

{#if theme === 'dark'}
    <img
        in:fly={{ y: -20, delay: 500 }}
        out:fly={{ y: -20 }}
        id="moon-theme-toggle"
        on:click={toggleTheme}
        src="/svg/theme/moon.svg"
        alt="toggle-theme-moon"
        style="top:.6rem" />
{/if}

{#if theme === 'light'}
    <img
        in:fly={{ y: -20, delay: 500 }}
        out:fly={{ y: -20 }}
        id="sun-mode-toggle"
        on:click={toggleTheme}
        src="/svg/theme/sun.svg"
        alt="toggle-theme-sun"
        style="top:.5rem" />
{/if}

<style lang="sass">img {
  cursor: pointer;
  position: absolute;
  right: .7rem;
  filter: none;
  transition: filter 400ms;
  z-index: 10;
  border: 1px solid transparent; }

img:hover {
  filter: sepia(1) saturate(5);
  transition: filter 600ms; }
</style>
