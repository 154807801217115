<script>
    export let username = '';
    export let password = '';
</script>

<form>
    <input
        required
        minlength="3"
        maxlength="25"
        autocomplete="username"
        id="username"
        type="text"
        bind:value={username}
        placeholder="Username" />

    <input
        required
        autocomplete="password"
        id="password"
        type="password"
        bind:value={password}
        placeholder="Password" />

    <div class="span" />

    <slot />
</form>

<style lang="sass">.span {
  height: 2rem; }

form {
  padding-top: 1.5rem; }

input {
  border: 1px solid var(--bg-secondary);
  font-family: var(--font-primary);
  outline-color: var(--brand);
  padding: 0.4rem 0.8rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  color: var(--dark);
  font-size: inherit;
  text-align: center;
  transition: 500ms;
  margin: 1rem auto;
  padding: 0.2rem;
  display: block;
  outline: none; }
</style>
