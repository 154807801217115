<script>
    import { fade, fly } from 'svelte/transition';
    import { url } from '@roxi/routify';
    import { LottiePlayer } from '@lottiefiles/svelte-lottie-player';
</script>

<div class="container" in:fade={{ delay: 200 }} out:fade={{ duration: 200 }}>
    <h1 in:fly={{ x: 10, duration: 1000, delay: 200 }} class="e4042">404</h1>
    <h1 in:fly={{ x: -10, duration: 1000, delay: 200 }} class="e404">404</h1>
    <h3>Hmmm... seems we're lost.</h3>
    <h2>Let's <a href={$url('/')}>Go Home</a></h2>
    <div class="spaceman">
        <LottiePlayer
            src="https://gist.githubusercontent.com/FractalHQ/9433b1d589db3b4f86b9f3861436606d/raw/8a7e8cffce0c44317fbda43b5a8a7b8c330a4409/astronaout.json"
            autoplay={true}
            loop={true}
            renderer="svg"
            background="transparent"
            height={300}
            width={300}
            controls={''}
            controlsLayout={''} />
    </div>
</div>

<style lang="sass">.container {
  text-align: center;
  width: 100vw;
  max-width: 100vw; }

.e404 {
  font-family: var(--font-secondary);
  color: var(--brand-swap-b);
  transition: 500ms;
  position: relative;
  font-size: 4rem;
  z-index: 1; }

.e4042 {
  transform: translateY(4rem) translateX(0.2rem);
  font-family: var(--font-secondary);
  color: var(--brand-swap-a);
  transition: 500ms;
  position: relative;
  font-size: 4rem;
  z-index: 0; }

h3 {
  font-family: var(--font-primary);
  opacity: 75%;
  font-size: 1rem;
  margin: 2rem auto 1remrem auto; }

h2 {
  font-family: var(--font-primary);
  font-size: 1.5rem;
  margin: 2rem auto 10rem auto; }

.spaceman {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; }

a {
  color: var(--brand-accent);
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  transition: 500ms;
  cursor: pointer; }

a:hover {
  text-decoration: underline;
  color: var(--brand);
  transition: 500ms; }
</style>
