<script>
    import Waves from './_components/shared/Waves.svelte';
    import { fly } from 'svelte/transition';
    import Time from './_components/shared/Time.svelte';
    import Date from './_components/shared/Date.svelte';
</script>

<div class="home">
    <div class="meditate-wrapper">
        <img
            class="meditate"
            in:fly={{ y: 200, delay: 1000, duration: 2000 }}
            out:fly={{ y: 200, duration: 500 }}
            src="svg/meditation.svg"
            alt="meditation" />
    </div>
    <div class="waves">
        <Waves />
    </div>
</div>
<div
    class="date"
    in:fly={{ x: -20, delay: 1000, duration: 2000 }}
    out:fly={{ x: -20 }}>
    <Date />
</div>
<div
    class="time"
    in:fly={{ y: -100, delay: 1000, duration: 2000 }}
    out:fly={{ x: -20 }}>
    <Time />
</div>

<style>
    .date {
        max-width: max-content;
        position: absolute;
        top: .5rem;
        left: 2.5rem;
    }
    .time {
        top: 0;
        font-size: 4rem;
        font-family: Courier New, Courier, monospace;
        position: absolute;
        text-align: center;
        color: var(--text);
        font-weight: 500;
        width: 100vw;
    }
    .home {
        position: absolute;
        max-height: 100vh;
        max-width: 100vw;
        overflow: hidden;
        height: 100vh;
        margin: auto;
        width: 100vw;
        z-index: -1;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }
    .meditate-wrapper {
        transform: translateY(10rem);
        object-position: center;
        pointer-events: none;
        display: flex-grow;
        user-select: none;
        margin: 2rem auto auto auto;
        width: 100%;
    }
    img {
        max-width: 800px;
        display: flex;
        margin: auto;
        z-index: -1;
    }
</style>
