<script>
    import ThemeToggle from '../_components/shared/ThemeToggle.svelte';
    import Spinner from '../_components/shared/Spinner.svelte';
    import Header from '../_components/header/Header.svelte';
    import SignUp from '../_components/auth/SignUp.svelte';
    import Login from '../_components/auth/Login.svelte';
    import { fade, fly } from 'svelte/transition';
    import { onMount } from 'svelte';
    import { ready } from '@roxi/routify';

    const { userbase } = window;
    window.userbase = null;

    let auth = false;
    let active = 'login';
    let userObject = null;

    async function initialize() {
        auth = await userbase
            .init({
                appId: '5f60b23a-afa4-429c-9aed-60da448fc6e7',
            })
            .then(({ user }) => (userObject = user))
            .catch((error) => console.error(error));
        $ready();
        return auth;
    }
    onMount(async () => (auth = await initialize()));

    let username = '';
    let password = '';
    let rememberMe = false;

    const signIn = () =>
        (auth = userbase
            .signIn({ username, password })
            .then((user) => (userObject = user)));

    const signUp = () =>
        (auth = userbase
            .signUp({ username, password })
            .then((user) => (userObject = user)));

    const signOut = () =>
        (auth = userbase.signOut().then(() => (userObject = null)));
</script>

{#if userObject}
    <Header />
    <slot />
    <div in:fade class="header">
        <p
            in:fly={{ x: -100, duration: 1000, delay: 500 }}
            out:fly={{ x: -50 }}
            class="username"
            contenteditable>
            {userObject.username}
        </p>
        <button
            in:fly={{ x: 100, duration: 1000, delay: 500 }}
            out:fly={{ y: -100 }}
            class="sign-out"
            on:click={signOut}><img
                in:fly={{ x: 100, duration: 1000, delay: 750 }}
                out:fly={{ x: 50 }}
                src="/svg/logout.svg"
                alt="logout" />
            <p style="margin-left:0.2rem;opacity:50%">Logout</p></button>
    </div>
{:else}
    <ThemeToggle />
    <h1 in:fade={{ delay: 500 }} out:fade class="welcome">
        Welcome to <span class="trak">Trak</span>
    </h1>
    <div in:fade={{ delay: 500 }} out:fade class="auth-container">
        <nav in:fly={{ y: 10, duration: 500, delay: 500 }} class="auth-nav">
            <ul class="login-signup">
                <li class="anim-link">
                    <h2
                        class:active={active === 'login'}
                        on:click={() => (active = 'login')}>
                        Login
                    </h2>
                </li>
                <li class="anim-link">
                    <h2
                        class:active={active === 'signup'}
                        on:click={() => (active = 'signup')}>
                        Sign Up
                    </h2>
                </li>
            </ul>
        </nav>

        <div class="spinner">
            {#await auth}
                <div transition:fade={{ duration: 300 }}>
                    <Spinner />
                </div>
            {:then _}
                <!--  -->
            {:catch error}
                <span id="status-error" class="status-error" in:fade>
                    ⓧ {error.message}
                </span>
            {/await}
        </div>

        <div class="auth-form">
            {#if active === 'login'}
                <div in:fly={{ x: -300, delay: 500 }} out:fly={{ x: -300 }}>
                    <Login bind:username bind:password>
                        <button on:click|preventDefault={signIn}>Login</button>
                    </Login>
                </div>
            {:else}
                <div in:fly={{ x: 300, delay: 500 }} out:fly={{ x: 300 }}>
                    <SignUp bind:username bind:password bind:rememberMe>
                        <button on:click|preventDefault={signUp}>Sign Up</button>
                    </SignUp>
                </div>
            {/if}
        </div>
    </div>
{/if}

<style lang="sass">.sections div img {
  margin: -1.2rem 0 0 -4rem;
  position: absolute;
  width: 5rem;
  float: left; }

nav {
  position: relative;
  display: flex; }

li h2 {
  color: var(--text-secondary);
  transition: color 500ms;
  padding-bottom: 0;
  user-select: none;
  font-size: 1.5rem;
  margin: 0 auto;
  padding: 1rem;
  width: 100%; }

li h2.active {
  color: var(--text);
  transition: color 500ms;
  font-weight: 600; }

ul.login-signup {
  justify-content: space-between;
  list-style-type: none;
  display: inline-flex;
  max-width: initial;
  text-align: left;
  width: 100%;
  padding-left: 0; }

li.anim-link {
  text-decoration: none;
  width: max-content;
  cursor: pointer;
  margin: 0 auto;
  display: block; }

.anim-link::after {
  background: var(--brand);
  transition: width 0.3s;
  display: block;
  content: '';
  height: 2px;
  width: 0; }

.anim-link:hover::after {
  transition: width 0.3s;
  width: 100%; }

.header {
  margin: 0 1rem;
  display: flex;
  width: 100vw; }

.username {
  font-family: var(--font-secondary);
  position: absolute;
  width: max-content;
  font-weight: 500;
  font-size: 2rem;
  left: 3.5rem;
  top: 0.3rem; }

.sign-out {
  color: var(--text);
  border: 1px solid transparent;
  margin: auto 0 auto auto;
  vertical-align: middle;
  padding: 0.5rem 1rem;
  position: absolute;
  line-height: 100%;
  transition: 500ms;
  background: none;
  font-size: 1rem;
  right: 3.5rem;
  top: 0.8rem; }

.sign-out:hover {
  border: 1px solid var(--brand-secondary);
  color: var(--text-secondary);
  transition: 500ms; }

.spinner {
  margin: auto 45% auto 45%;
  object-position: center;
  padding-bottom: 0.1rem;
  text-align: center;
  height: 0.3rem;
  display: grid; }

.status-error {
  transform: translateY(-9rem);
  margin: auto auto 0 auto;
  text-align: center;
  position: absolute;
  color: #ff8758c6;
  width: 18rem;
  right: 1rem;
  left: 1rem; }

button {
  margin: 0 auto 1rem auto;
  display: flex; }

.welcome {
  margin: 1rem 0 0 1rem;
  font-size: 2rem; }

.trak {
  color: var(--brand);
  font-weight: 800; }

.auth-form {
  max-height: initial;
  overflow: hidden; }

.auth-form button {
  color: var(--bg);
  background-color: var(--brand); }

.auth-container {
  box-shadow: var(--box-shadow) var(--shadow);
  background: var(--bg-secondary);
  border: solid 1px var(--brand);
  transition: background 700ms;
  max-height: initial;
  border-radius: 1rem;
  margin: 128px auto;
  width: max-content;
  margin: 128px auto;
  text-align: center;
  user-select: none;
  height: 21rem;
  width: 20rem; }

@media only screen and (max-width: 600px) {
  .sign-out {
    right: 2.5rem;
    top: .4rem; }
  .username {
    margin: auto 0 auto -0.5rem;
    font-size: 1.5rem; } }
</style>
