<script>
    import { slide, fly, fade } from 'svelte/transition';
    import { flip } from 'svelte/animate';
    import { simpleTime } from './time/utils/utils.js';
    import format from 'date-fns/format';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    function deleteTask(id) {
        dispatch('deleteTask', { id: id });
    }

    export let tasks = [];
</script>

<ol class="list">
    {#each [...tasks].reverse() as task (task)}
        <li
            in:slide={{ y: -20, duration: 300, delay: 50 }}
            animate:flip={{ duration: 300 }}>
            <p class="date"><sup>{format(task.date, 'h:mm a')}</sup></p>
            <h2 class="task-name" contenteditable>{task.title}</h2>
            <p class="tags"><sup>{task.tags.join(' · ')}</sup></p>
            <p class="duration">{simpleTime(task.duration)}</p>
            <h3 class="delete" on:click={() => deleteTask(task.id)}>ⓧ</h3>
        </li>
    {/each}
</ol>

<style lang="sass">.delete {
  cursor: pointer;
  color: gray; }
  .delete:hover {
    color: lightcoral; }

ol {
  overflow-y: auto;
  padding: 0 0.5rem;
  width: clamp(20rem, 40rem, 90vw);
  margin: 0 auto 1rem auto;
  height: full;
  max-height: 40vmin;
  position: relative; }

li {
  border-bottom: 1px solid #b3b3b3;
  align-items: center;
  overflow-y: none;
  padding: 0.5rem 0;
  display: flex; }

ol > li + li {
  margin-top: 0.5rem; }

p {
  font-family: var(--font-primary); }

.date {
  text-align: left;
  font-size: 1rem;
  outline: none;
  margin-right: 5rem; }

sup {
  background-color: none;
  font-size: 0.75rem; }

.tags {
  margin-left: auto;
  font-size: 1.15rem;
  text-align: left; }

.duration {
  margin-left: 2rem;
  margin-right: 1rem;
  text-align: right;
  width: max-content; }

/* for -webkit supported browsers style the scrollbar closer to the overall ui */
ol::-webkit-scrollbar {
  width: 6px; }

ol::-webkit-scrollbar-track {
  background: #cccccc;
  border-radius: 3px; }

ol::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
  border-radius: 3px; }

h2, h3 {
  font-family: var(--font-primary); }

@media only screen and (max-width: 600px) {
  ol {
    max-height: 80vmin;
    margin: 0; }
  .task-name {
    margin-left: -10vw; } }

@media only screen and (min-width: 600px) {
  .task-name {
    margin-left: -1vw; }
  ol {
    max-height: 35vmin;
    margin: 7rem auto 1rem auto;
    transform: translateY(-6rem); } }
</style>
