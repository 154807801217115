<script>
    import { formatTime } from './utils/utils.js';
    import { tweened } from 'svelte/motion';

    // duration refers to the number of milliseconds in the stopwatch
    export let duration = 0;

    // rotation refers to the degrees applied to the minutes dial to have a full rotation for 60 seconds
    // multiply the value by 60 for the seconds dial to have a full rotation every second

    $: rotation = ((duration / 1000 / 60) * 360) % 360;

    // this is a very imperfect way to have the dials rotate smoothly back to 0
    // set a transition on the minutes and seconds dial, but only when duration is set to 0
    // remove it when duration is then more than 0
    let seconds;
    let minutes;
    // to avoid constantly setting transition to none add a boolean to short-circuit the second conditional
    let transitioned;

    // minutes and seconds are undefined by default
    $: if (!duration && minutes && seconds) {
        minutes.style.transition = 'transform 0.2s ease-in-out';
        seconds.style.transition = 'transform 0.2s ease-in-out';
        transitioned = false;
    }
    $: if (duration && !transitioned) {
        minutes.style.transition = 'none';
        seconds.style.transition = 'none';
        transitioned = true;
    }

    // onMount(resize);
</script>

<!-- <svelte:window bind:innerWidth={screenWidth} on:resize={resize} /> -->

<div class="container">
    <div class="stopwatch">
        <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMin">
            <g transform="translate(50 50)">
                <circle
                    id="dial"
                    cx="0"
                    cy="0"
                    r="42"
                    fill="none"
                    stroke-width="5"
                    stroke={transitioned && duration ? 'var(--brand)' : 'var(--text-secondary)'}
                    stroke-dasharray="0.3 1.898"
                    style="transition:500ms" />

                <use href="#dial" transform="scale(-1 1)" />

                <!-- include the number of milliseconds in the rotation of the minutes dial
        1 full rotation for every 60 seconds -->

                <g bind:this={minutes} transform="rotate({rotation})">
                    <g transform="translate(0 -50)">
                        <path
                            d="M -2.25 0 h 4.5 l -2.25 2.5 l -2.25 -2.5"
                            fill="currentColor"
                            stroke="var(--brand)"
                            stroke-width="1"
                            stroke-linejoin="round"
                            stroke-linecap="round" />
                    </g>
                </g>

                <g transform="translate(0 20)">
                    <!-- include the number of milliseconds in the rotation of the minutes dial
            1 full rotation for every single second
            -->
                    <g
                        bind:this={seconds}
                        transform="rotate({(rotation * 60) % 360})">
                        <path
                            d="M 0 -1 v -4.5"
                            fill="none"
                            stroke="var(--brand)"
                            stroke-width="0.5"
                            stroke-linejoin="round"
                            stroke-linecap="round" />
                    </g>
                    <circle
                        r="7"
                        fill="none"
                        stroke="var(--text-secondary)"
                        stroke-width="0.4" />
                    <circle
                        r="1"
                        fill="none"
                        stroke="var(--text-secondary)"
                        stroke-width="0.4" />
                </g>

                <text
                    text-anchor="middle"
                    fill={transitioned ? 'var(--brand)' : 'currentColor'}
                    dominant-baseline="middle"
                    font-size="15"
                    style="font-weight: 400; letter-spacing: 1px;">
                    {formatTime(duration)}
                </text>
            </g>
        </svg>
    </div>
</div>

<style lang="sass">text {
  font-kerning: auto;
  font-family: 'Roboto Mono', monospace; }

.container {
  object-position: center;
  display: flex;
  width: 100%;
  height: 100%; }

@media only screen and (max-width: 600px) {
  .stopwatch {
    margin: 0 auto;
    transform: translateY(0rem); }
  svg {
    font-family: 'Roboto Mono', monospace;
    color: var(--text);
    transition: 300ms;
    width: 125px; } }

@media only screen and (min-width: 600px) {
  .stopwatch {
    display: inline-block;
    transition: 300ms;
    margin: auto;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0; }
  svg {
    width: 150px; } }
</style>
