<script>
    import { fly } from 'svelte/transition';
    import { url, isActive, prefetch } from '@roxi/routify';
</script>

<nav
    id="nav"
    in:fly={{ y: -50, delay: 400, duration: 800 }}
    out:fly={{ y: -50 }}>
    <div id="nav__trak">
        <a
            id="nav__trak__link"
            href={$url('./trak')}
            class:active={$isActive('./trak')}
            on:mouseover={() => prefetch('/trak')}>
            Trak <img id="nav__trak__link__svg" src="/svg/time/time-1.svg" alt="clock" />
        </a>
    </div>
    <div id="nav__view">
        <a
            id="nav__view__link"
            href={$url('./view')}
            class:active={$isActive('./view')}
            on:mouseover={() => prefetch('/view')}>
            View <img id="nav__view__link__svg" src="/svg/time/time-3.svg" alt="view" />
        </a>
    </div>
    <div id="nav__report">
        <a
            id="nav__report__link"
            href={$url('./report')}
            class:active={$isActive('./report')}
            on:mouseover={() => prefetch('/report')}>
            Report <img id="nav__report__link__svg" src="/svg/time/time-4.svg" alt="Report" />
        </a>
    </div>
</nav>

<style lang="sass">nav {
  margin: 2.5rem auto 1rem auto;
  position: relative;
  max-width: 50rem;
  display: flex;
  z-index: 1;
  user-select: none; }

nav div {
  margin: auto; }

nav div img {
  margin: -1.2rem 0 0 -4rem;
  position: absolute;
  padding: none;
  width: 5rem; }

nav div a {
  border: solid 2px var(--brand);
  background: var(--brand);
  color: var(--dark);
  font-family: var(--font-primary);
  padding: 0.5rem 1.5rem;
  border-radius: 0.3rem;
  text-decoration: none;
  letter-spacing: 1px;
  border-width: 1px;
  transition: 500ms;
  font-size: medium;
  font-weight: 600;
  display: flex;
  margin: 0 2rem 0 2rem; }

nav div a:hover {
  color: var(--text-secondary);
  transition: 300ms;
  background: none; }

nav div a:active {
  background: transparent; }

@media only screen and (min-width: 600px) {
  nav {
    top: -2rem; } }

@media only screen and (max-width: 800px) and (min-width: 600px) {
  img {
    visibility: collapse; }
  nav div a {
    padding: .5rem;
    margin: 0 1rem 0 1rem; } }

@media only screen and (max-width: 600px) {
  nav {
    position: fixed;
    width: 100vw;
    bottom: 0; }
  nav div img {
    transform: translateX(1rem) translateY(0.4rem);
    width: 4rem; }
  nav div a {
    letter-spacing: normal;
    padding: 0.5rem 1rem;
    margin: 0; } }
</style>
