<script>
    import { createEventDispatcher } from 'svelte';
    import { fly } from 'svelte/transition';

    const dispatch = createEventDispatcher();
    let visible = false;

    function deleteAll() {
        dispatch('deleteAll');
    }
    function exportCSV() {
        alert('Coming Soon!');
    }
</script>

<img
    in:fly={{ x: 20, duration: 700, delay: 1000 }}
    out:fly={{ x: 20 }}
    src="svg/settings.svg"
    alt="settings button"
    on:click={() => (visible = !visible)} />
<div>
    {#if visible}
        <div transition:fly={{ x: 200 }}>
            <button on:click={() => exportCSV()}>Download CSV</button>
            <button class="delete" on:click={deleteAll}>Delete All</button>
        </div>
    {/if}
</div>

<style lang="sass">img {
  margin: .1rem 1.05rem 1rem .7rem;
  max-width: initial;
  cursor: pointer;
  opacity: 80%;
  width: 30px;
  float: right;
  transition: filter 300ms; }
  img:hover {
    filter: brightness(0.3);
    transition: filter 300ms; }

div {
  display: grid;
  float: right;
  margin: 1.2rem -1rem 0 0; }

.delete {
  border-color: lightcoral;
  color: lightcoral; }
  .delete:hover {
    background: lightcoral;
    color: whitesmoke; }

button {
  border: 1px solid var(--text-secondary);
  margin: .5rem 0 0 1rem;
  font-size: .8rem;
  padding: .3rem;
  background: transparent; }
  button:hover {
    background: lightblue;
    border-color: whitesmoke; }
</style>
