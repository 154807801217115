<script>
    import { fly, fade } from 'svelte/transition';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    function start() {
        dispatch('start');
    }
    function stop() {
        dispatch('stop');
    }
    function pause() {
        dispatch('pause');
    }

    export let recording;
    export let lapsed;
</script>

<div class="start-stop">
    <div class="start-stop">
        {#if recording}
            <button class="pause" on:click={pause}>
                <img src="/svg/time/PauseButton.svg" alt="Pause Button" />
            </button>
        {:else if lapsed}
            <button class="reset" in:fly={{ x: 100 }} out:fade on:click={stop}>↩
                Reset</button>
            <button on:click={start} class="pause">
                <img src="/svg/time/ContinueButton.svg" alt="Continue Button" />
            </button>
        {:else}
            <button
                in:fly={{ x: 200, delay: 1000 }}
                out:fly={{ x: 200 }}
                on:click={start}
                class="start">
                <img src="/svg/time/StartButton.svg" alt="Start Button" />
            </button>
        {/if}
    </div>
</div>

<style lang="sass">.start, .pause {
  position: absolute;
  bottom: 0;
  right: 0; }

.start-stop {
  position: fixed;
  right: .5rem;
  bottom: 2rem;
  outline: 0; }
  .start-stop button:focus {
    outline: 0; }

.reset {
  margin: 0 1.5rem 9rem 0;
  color: var(--text-secondary);
  position: fixed;
  right: .5rem;
  bottom: 0;
  cursor: pointer; }

@media only screen and (max-width: 600px) {
  .reset {
    bottom: -8.8rem;
    right: -3rem;
    position: absolute; }
  img {
    width: 75%; }
  .start, .pause {
    position: absolute;
    bottom: -5.4rem;
    right: -7rem; }
  .start-stop {
    right: 1.7rem;
    position: relative; } }

@media only screen and (min-width: 600px) {
  .start-stop {
    right: 2rem; }
  .reset {
    right: 2rem;
    bottom: 6rem; } }

div button:focus {
  outline-color: #404040; }

div button:hover {
  border-radius: 9999px;
  transition: background 500ms; }

div button {
  background: none;
  border: none;
  padding: 0; }
</style>
